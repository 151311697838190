export const courseEdges = [
  // PLANNED
  {
    source: '6.006',
    target: '6.172',
    id: '6.006-6.172',
    label: '6.006-6.172',
  },
  {
    source: '6.004',
    target: '6.172',
    id: '6.004-6.172',
    label: '6.004-6.172',
  },
  {
    source: '6.031',
    target: '6.172',
    id: '6.031-6.172',
    label: '6.031-6.172',
  },
  {
    source: '6.004',
    target: '6.039',
    id: '6.004-6.039',
    label: '6.004-6.039',
  },
  {
    source: '6.036',
    target: '6.S953',
    id: '6.036-6.S953',
    label: '6.036-6.S953',
  },

  // PAST
  // Theory
  // {
  //   source: '18.01',
  //   target: '6.042',
  //   id: '18.01-6.042',
  //   label: '18.01-6.042',
  // },
  {
    source: '6.042',
    target: '6.006',
    id: '6.042-6.006',
    label: '6.042-6.006',
  },
  {
    source: '6.006',
    target: '6.046',
    id: '6.006-6.046',
    label: '6.006-6.046',
  },
  {
    source: '18.600',
    target: '6.046',
    id: '18.600-6.046',
    label: '18.600-6.046',
  },

  // Programming
  // {
  //   source: '6.0001',
  //   target: '6.009',
  //   id: '6.0001-6.009',
  //   label: '6.0001-6.009',
  // },
  {
    source: '6.009',
    target: '6.031',
    id: '6.009-6.031',
    label: '6.009-6.031',
  },

  // Systems
  // {
  //   source: '6.0001',
  //   target: '6.004',
  //   id: '6.0001-6.004',
  //   label: '6.0001-6.004',
  // },
  {
    source: '8.02',
    target: '6.004',
    id: '8.02-6.004',
    label: '8.02-6.004',
  },
  {
    source: '6.004',
    target: '6.033',
    id: '6.004-6.033',
    label: '6.004-6.033',
  },
  {
    source: '6.033',
    target: '6.824',
    id: '6.033-6.824',
    label: '6.033-6.824',
  },

  // ML/AI
  {
    source: '18.06',
    target: '6.036',
    id: '18.06-6.036',
    label: '18.06-6.036',
  },
  {
    source: '18.06',
    target: '6.864',
    id: '18.06-6.864',
    label: '18.06-6.864',
  },
  {
    source: '18.06',
    target: '6.819',
    id: '18.06-6.819',
    label: '18.06-6.819',
  },
  // {
  //   source: '18.02',
  //   target: '6.036',
  //   id: '18.02-6.036',
  //   label: '18.02-6.036',
  // },
  {
    source: '6.036',
    target: '6.864',
    id: '6.036-6.864',
    label: '6.036-6.864',
  },
  {
    source: '6.036',
    target: '6.819',
    id: '6.036-6.819',
    label: '6.036-6.819',
  },
  {
    source: '6.042',
    target: '6.819',
    id: '6.042-6.819',
    label: '6.042-6.819',
  },

  // Korean
  {
    source: '21G.904',
    target: '21G.905',
    id: '21G.904-21G.905',
    label: '21G.904-21G.905',
  },
  {
    source: '21G.905',
    target: '21G.906',
    id: '21G.905-21G.906',
    label: '21G.905-21G.906',
  },

  // Other
  // {
  //   source: '18.01',
  //   target: '18.02',
  //   id: '18.01-18.02',
  //   label: '18.01-18.02',
  // },
  // {
  //   source: '18.02',
  //   target: '18.600',
  //   id: '18.02-18.600',
  //   label: '18.02-18.600',
  // },
  // {
  //   source: '8.01',
  //   target: '8.02',
  //   id: '8.01-8.02',
  //   label: '8.01-8.02',
  // },
  // {
  //   source: '18.01',
  //   target: '8.02',
  //   id: '18.01-8.02',
  //   label: '18.01-8.02',
  // },
  // {
  //   source: '18.02',
  //   target: '18.06',
  //   id: '18.02-18.06',
  //   label: '18.02-18.06',
  // },
];
